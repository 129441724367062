import React from 'react';
import PropTypes from 'prop-types';
import { HTMLtoJSX } from '../../../../../../helpers';
import './FestivalFooterCopyright.scss';

export default function FestivalFooterCopyright({ copyright }) {
  return (
    <div className="c-festival-footer-copyright">{HTMLtoJSX(copyright)}</div>
  );
}

FestivalFooterCopyright.propTypes = {
  copyright: PropTypes.string,
};
