import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImageTag from '../../../../../shared/ImageTag/ImageTag';

import './FestivalFooterSocialNetwork.scss';

import facebook from '../../../../../../assets/images/festivals/social/icon-facebook.png';
import twitter from '../../../../../../assets/images/festivals/social/icon-twitter.png';
import youtube from '../../../../../../assets/images/festivals/social/icon-youtube.png';
import linkedin from '../../../../../../assets/images/festivals/social/icon-linkedin.png';

const socialNetworkIconsConfig = {
  facebook,
  twitter,
  youtube,
  linkedin,
};

const FAIcon = styled.i`
  color: #ffffff;
`;

export default function FestivalFooterSocialNetwork(props) {
  const {
    config: { heading = '', menuConfig = [] },
  } = props;

  return (
    <nav className="c-festival-social-network">
      {heading && (
        <h4 className="c-festival-social-network__heading">{heading}</h4>
      )}
      <ul>
        {menuConfig.map(({ path, icon, faIcon }, index) => (
          <li key={index}>
            <a href={path} target="_blank" rel="noopener noreferrer">
              {faIcon ? (
                <FAIcon className={faIcon} />
              ) : (
                <ImageTag
                  src={socialNetworkIconsConfig[icon]}
                  alt={icon}
                  imgix={false}
                  lazy={false}
                  style={{ height: '40px' }}
                />
              )}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

FestivalFooterSocialNetwork.propTypes = {
  config: PropTypes.shape({
    heading: PropTypes.string,
    menuConfig: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        icon: PropTypes.string,
        faIcon: PropTypes.string,
      }),
    ),
  }),
};
