/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import { isAbsoluteUrl } from '../../../../../../helpers';

import './FestivalFooterNavigation.scss';

export default function FestivalFooterNavigation(props) {
  const { menuConfig, showScrollToTop } = props;

  return (
    <nav className="c-festival-footer-navigation">
      <ul>
        {menuConfig.map(({ path, label, target }, index) => {
          const defaultTarget = isAbsoluteUrl(path) ? '_blank' : '_self';

          return (
            <li key={index}>
              <a
                href={path}
                target={target || defaultTarget}
                rel={isAbsoluteUrl(path) ? 'noopener noreferrer' : undefined}
              >
                {label}
              </a>
            </li>
          );
        })}
        {showScrollToTop && (
          <li>
            <span
              onClick={Scroll.animateScroll.scrollToTop}
              className="c-festival-footer-navigation__scroll-top-top"
            >
              Back to top <i className="fas fa-chevron-up" />
            </span>
          </li>
        )}
      </ul>
    </nav>
  );
}

FestivalFooterNavigation.propTypes = {
  menuConfig: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      target: PropTypes.string,
    }),
  ),
  showScrollToTop: PropTypes.bool,
};
